@use 'sass:map';
@use '@angular/material' as mat;

// set the colors of the component according to the theme
@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the palettes from the color-config.
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  $accent100: mat.get-color-from-palette($accent-palette, 100);
  $warn100: mat.get-color-from-palette($warn-palette, 100);
  .group-card {
    background-color: $accent100;
    border-color: $accent100 !important;
  }
  .myGroup {
    background-color: $warn100;
  }
}

// set the theme for the component
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
