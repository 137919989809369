@charset "UTF-8";
@use 'src/app/shared/styles/variables' as v;

// code is taken from https://stackoverflow.com/questions/61939970/media-queries-angular-material

// Non-overlapping Material Design breakpoints
// @type map
$breakpoints: (
  xs: (
    begin: 0,
    end: 599.9px,
  ),
  sm: (
    begin: 600px,
    end: 959.9px,
  ),
  md: (
    begin: 960px,
    end: 1279.9px,
  ),
  lg: (
    begin: 1280px,
    end: 1919.9px,
  ),
  xl: (
    begin: 1920px,
    end: 4999.99px,
  ),
) !default;

// Overlapping breakpoints that are greater than defined
// Material Design breakpoints
// @type map
$overlapping-gt: (
  gt-xs: 600px,
  gt-sm: 960px,
  gt-md: 1280px,
  gt-lg: 1920px,
) !default;

// Overlapping breakpoints that are less than defined
// Material Design breakpoints
// @type map
$overlapping-lt: (
  lt-sm: 599.9px,
  lt-md: 959.9px,
  lt-lg: 1279.9px,
  lt-xl: 1919.9px,
) !default;

@mixin layout-bp($bp) {
  @if map-has-key($breakpoints, $bp) {
    $min: map-get(map-get($breakpoints, $bp), begin);
    $max: map-get(map-get($breakpoints, $bp), end);
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if map-has-key($overlapping-gt, $bp) {
    $min: map-get($overlapping-gt, $bp);
    @media (min-width: $min) {
      @content;
    }
  } @else if map-has-key($overlapping-lt, $bp) {
    $max: map-get($overlapping-lt, $bp);
    @media (max-width: $max) {
      @content;
    }
  }
}

// $allkeys: map_keys(
//   map_merge($breakpoints, map_merge($overlapping-gt, $overlapping-lt))
// );

// @each $key in $allkeys {
//   @include layout-bp($key) {
//     .ge-display-none-#{$key} {
//       display: none !important;
//     }
//     .ge-display-block-#{$key} {
//       display: block !important;
//     }
//     .ge-display-inline-#{$key} {
//       display: inline !important;
//     }
//     .ge-padding-0-#{$key} {
//       padding: 16px !important;
//     }
//     .ge-padding-16px-#{$key} {
//       padding: 0 !important;
//     }
//     .ge-margin-0-#{$key} {
//       margin: 16px !important;
//     }
//     .ge-margin-16px-#{$key} {
//       margin: 0 !important;
//     }
//   }
// }

.grid-container {
  width: 100%;
  align-items: start;
  display: grid;
  gap: v.$margin v.$margin;
  grid-auto-flow: row;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
}
