@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the accent color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  $gray700: mat.get-color-from-palette($accent-palette, 700);
  $gray600: mat.get-color-from-palette($accent-palette, 600);
  .iv-title {
    color: $gray700 !important;
  }
  .iv-subtitle {
    color: $gray600 !important;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
