@use 'sass:map';
@use '@angular/material' as mat;

// set the colors of the component according to the theme
@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the palettes from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  $primary-color: mat.get-color-from-palette($primary-palette, 400);
  $secondary-color: mat.get-color-from-palette($primary-palette, 100);
  .no-group-hint, .post-card-hint {
    background-color: $secondary-color;
  }
  .post-card-hint-title, .no-group-hint-title{
    color: $primary-color;
  }
}

// set the theme for the component
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
