@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  $gray50: mat.get-color-from-palette($accent-palette, 50);
  $gray300: mat.get-color-from-palette($accent-palette, 300);
  $gray300Contrast: mat.get-contrast-color-from-palette($accent-palette, 300);
  $gray400: mat.get-color-from-palette($accent-palette, 400);
  $gray900: mat.get-color-from-palette($accent-palette, 900);
  .route-button {
    background-color: transparent;
    transition: all 1s;
    &:disabled {
      background-color: $gray300;
      color: $gray300Contrast;
    }
    &:hover {
      background-color: $gray300 !important;
    }
    &:active {
      background-color: $gray300;
    }
    mat-icon:not(:disabled){
      color: $gray900;
    }
    .mdc-button__label:not(:disabled){
      color: $gray900;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
