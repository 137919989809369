@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  $gray100: mat.get-color-from-palette($accent-palette, 100);
  $gray300: mat.get-color-from-palette($accent-palette, 300);
  .avatar-container.hover-enabled {
    transition: all 1s;
    border-radius: 8px;
    &:hover {
      background-color: $gray300;
    }
  }

  .avatar {
    &.no-image {
      background-color: $gray100;
    }
    .text {
      color: mat.get-color-from-palette($accent-palette, '100-contrast');
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
