@use 'sass:map';
@use '@angular/material' as mat;

// set the colors of the component according to the theme
@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  $secondaryColor: mat.get-color-from-palette($primary-palette, 50);
  $primaryColor: mat.get-color-from-palette($primary-palette, 400);
  .follow-event-container {
    background-color: $secondaryColor;
    .left {
      mat-label {
        color: $primaryColor;
      }
      .icon {
        color: $primaryColor;
      }
    }
  }
}

// set the theme for the component
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
