/* generated with https://gwfh.mranftl.com/fonts/inter?subsets=latin */
/* inter-100 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/inter-v12-latin-100.woff2') format('woff2'); /* Super Modern Browsers */
}
/* inter-200 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/inter-v12-latin-200.woff2') format('woff2'); /* Super Modern Browsers */
}
/* inter-300 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/inter-v12-latin-300.woff2') format('woff2'); /* Super Modern Browsers */
}
/* inter-regular - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/inter-v12-latin-regular.woff2') format('woff2'); /* Super Modern Browsers */
}
/* inter-500 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/inter-v12-latin-500.woff2') format('woff2'); /* Super Modern Browsers */
}
/* inter-600 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/inter-v12-latin-600.woff2') format('woff2'); /* Super Modern Browsers */
}
/* inter-700 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/inter-v12-latin-700.woff2') format('woff2'); /* Super Modern Browsers */
}
