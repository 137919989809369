/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@use 'src/app/shared/styles/variables' as v;
@use './_palettes' as p;
@use 'src/app/shared/styles/responsive.mixin' as rsp;
@use './fonts';
@use './assets/fa-scss/fontawesome';
@use './assets/fa-scss/regular';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.v-center {
  display: flex;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

// define scrollbar design
div,
.iv-sidenav-content,
textarea,
iv-two-column-dialog-content,
iv-scrollable {
  scrollbar-width: thin !important;
  scrollbar-color: gray lightgrey !important;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: lightgrey;
  }
  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}

.ivp .mat-drawer-container {
  // override background color for sidenav
  background-color: white !important;
}

.dialog {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
}

.divider {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.ivp .mat-elevation-z10 {
  // disable shadow for sidenav
  box-shadow: 0 0 0 0 !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mdc-dialog__surface {
  // radius of the corners of all dialog an messageboxes
  border-radius: 8px !important;
}

.mdc-tooltip__surface {
  white-space: pre-wrap;
  max-width: unset !important;
}

.mdc-dialog__title::before {
  height: unset !important;
}

// overrides the padding for the mat dialog content. currently only used by messagebox
.mdc-dialog__content {
  padding: 0 v.$marginDialog 0 v.$marginDialog !important;
}

// disables globaly the margin for h2
.mat-typography h2 {
  margin: 0px !important;
}

// template override style for message boxes
.messagebox-override {
  margin: v.$margin;
  width: 35rem;
  @include rsp.layout-bp('lt-sm') {
    margin: v.$margin 0 v.$margin 0 !important;
    width: 95dvw;
    max-width: unset !important;
  }
}

// template override style for snackbars
.snackbar-override {
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  white-space: pre-wrap;
  @include rsp.layout-bp('lt-sm') {
    margin: v.$margin 0 v.$margin 0 !important;
    width: 95dvw;
    min-width: 95dvw;
  }
}

// made possible that dialogs might get the size of 95dvh instead of 85dvh
// this is important for the mobile view
.cdk-overlay-pane {
  max-width: 95dvw !important;
  max-height: 95dvh !important;
}

// error of form field should take its place
.mat-mdc-form-field-error-wrapper {
  position: unset !important;
}

// error of form field should be top aligned
.mat-mdc-form-field-bottom-align::before {
  display: unset !important;
}

// correct width and height of form fields
.mat-mdc-form-field {
  display: unset !important;
}

$iconSize: 16px;
.mat-icon {
  font-size: $iconSize !important;
  height: $iconSize !important;
  width: $iconSize !important;
  padding: 4px 4px 4px 4px;
}

.ivp {
  .mat-headline-6,
  .mat-subtitle-1,
  h1,
  h2,
  .mdc-list-item,
  .mat-mdc-input-element,
  .mat-drawer-container,
  mat-checkbox label.mdc-label {
    color: map.get(p.$ivp-accent-palette, 900) !important;
  }
  .mdc-dialog__content {
    color: map.get(p.$ivp-accent-palette, 600) !important;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: map.get(p.$ivp-accent-palette, 100) !important;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):not(.mat-mdc-chip-selected):not(.mat-mdc-chip-highlighted) {
    background-color: map.get(p.$ivp-accent-palette, 100) !important;
  }
  mat-checkbox {
    margin-left: -8px;
  }
  .mat-error-hint {
    color: map.get(p.$ivp-warn-palette, A400) !important;
    font-size: 12px;
    padding: 0px;
    margin: 0px 0px 0px 16px;
    display: block;
    line-height: 1.5rem;
    animation: fadeIn 1s;
  }
  a {
    text-decoration: none;
    color: map.get(p.$ivp-primary-palette, 400) !important;
    cursor: pointer;
  }
  /* unvisited link */
  a:link {
    color: map.get(p.$ivp-primary-palette, 400) !important;
  }

  /* visited link */
  a:visited {
    color: map.get(p.$ivp-primary-palette, 200) !important;
  }

  /* mouse over link */
  a:hover {
    color: map.get(p.$ivp-primary-palette, 700) !important;
  }

  /* selected link */
  a:active {
    color: map.get(p.$ivp-primary-palette, 900) !important;
  }

  .mat-body-1 {
    &.medium {
      font-weight: 600;
    }
  }
}
