@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  $primaryColor: mat.get-color-from-palette($primary-palette, 400);
  $secondaryColor: mat.get-color-from-palette($primary-palette, 100);
  .beta-container {
    border-color: $primaryColor !important;
    background-color: $secondaryColor !important;
  }

}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
