@use 'sass:map';
@use '@angular/material' as mat;

// set the colors of the component according to the theme
@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  $primary100: mat.get-color-from-palette($primary-palette, 100);
  $gray100: mat.get-color-from-palette($accent-palette, 100);
  .chat-message-container .message {
    background-color: $gray100;
    color: mat.get-color-from-palette($primary-palette, '100-contrast');
    &.is-mine {
      background-color: $primary100;
      color: mat.get-color-from-palette($primary-palette, '100-contrast');
    }
  }
}

// set the theme for the component
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
