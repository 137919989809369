@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  h1, h2, p, a, span, small {
    font-family: mat.font-family($typography-config);
  }
  mat-checkbox label.mdc-label,
  mat-radio-button label.mdc-label {
    @include mat.typography-level($typography-config, 'body-1');
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
