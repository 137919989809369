@use '@angular/material' as mat;
@use './app/ivp/components/chat-message/chat-message.component-theme';
@use './app/ivp/components/event-card/event-card.component-theme';
@use './app/ivp/components/follow-event/follow-event.component-theme';
@use './app/ivp/components/group-card/group-card.component-theme';
@use './app/ivp/components/group-card-small/group-card-small.component-theme';
@use './app/ivp/components/not-available-card/not-available-card.component-theme.scss';
@use './app/ivp/dialogs/invitation-dialog/invitation-dialog.component-theme.scss';
@use './app/ivp/dialogs/profile-view-dialog/profile-view-dialog.component-theme.scss';
@use './app/shared/components/avatar/avatar.component-theme';
@use './app/shared/components/button/button.component-theme';
@use './app/shared/components/dialog-header/dialog-header.component-theme';
@use './app/shared/components/icon-button/icon-button.component-theme';
@use './app/shared/components/image/image.component-theme';
@use './app/shared/components/two-column/two-column.component-theme';
@use './app/shared/components/beta-label/beta-label.component-theme';
@use './app/app.component-theme';
@use './_palettes' as p;

@include mat.core();

// ### IVP Theme

// $font-size,
// $font-size,
// $font-weight: 400,
// $font-family: null,
// $letter-spacing: normal

// TODO use always rem instead of px and use for better understanding the full attribute name
$my-custom-typography-config: mat.define-typography-config(
  $font-family: 'Inter',
  /* .mat-headline-1, none native */ $headline-1: mat.define-typography-level(112px, 112px, 300),
  /* .mat-headline-2, none native */ $headline-2: mat.define-typography-level(56px, 56px, 400),
  /* .mat-headline-3, none native */ $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  /* .mat-headline-4, none native */ $headline-4: mat.define-typography-level(34px, 40px, 400),
  /* <h1>  .mat-h1 or .mat-headline-5 */ $headline-5:
    mat.define-typography-level(
      $font-size: 1.875rem,
      $line-height: 2.375rem,
      $font-weight: 600,
    ),
  /* <h2> .mat-h2 or .mat-headline-6 */ $headline-6:
    mat.define-typography-level(
      $font-size: 1.25rem,
      $line-height: 2rem,
      $font-weight: 600,
    ),
  /* <h3> .mat-h3 or .mat-subtitle-1 */ $subtitle-1:
    mat.define-typography-level(
      $font-size: 1.25rem,
      $line-height: 2rem,
      $font-weight: 600,
    ),
  /* .mat-body-strong, .mat-subtitle-2 */ $subtitle-2:
    mat.define-typography-level(
      $font-size: 1rem,
      $line-height: 1.125rem,
      $font-weight: 600,
    ),
  /* <h4> .mat-h4 or .mat-body-1 */ $body-1:
    mat.define-typography-level(
      $font-size: 1rem,
      $line-height: 1.5rem,
      $font-weight: 400,
    ),
  /* .mat-body, .mat-body-2, Secondary body text.*/ $body-2:
    mat.define-typography-level(
      $font-size: 0.875rem,
      $line-height: 1.25rem,
      $font-weight: 400,
    ),
  /* .mat-small, .mat-caption, Smaller body and hint text. */ $caption:
    mat.define-typography-level(
      $font-size: 0.75rem,
      $line-height: 1.125rem,
      $font-weight: 400,
    ),
  /* Buttons and anchors. */ $button:
    mat.define-typography-level(
      $font-size: 1rem,
      $line-height: 1.5rem,
      $font-weight: 400,
    ),
);

$ivp-theme: mat.define-light-theme(
  (
    color: (
      /** parameters are "palette", "default" hue, a "lighter" hue, a "darker" hue, and a "text" hue.*/
      primary: mat.define-palette(p.$ivp-primary-palette, 400, 50, 900, A700),
      accent: mat.define-palette(p.$ivp-accent-palette, 700),
      warn: mat.define-palette(p.$ivp-warn-palette, A400, A100, A700),
    ),
    typography: $my-custom-typography-config,
    density: 0,
  )
);

// TODO Keep this for later use!
// $blue-theme: mat.define-dark-theme(
//   (
//     color: (
//       primary: mat.define-palette(mat.$blue-palette, 400),
//       accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
//     ),
//     typography: $my-custom-typography-config,
//     density: 0,
//   )
// );

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($ivp-theme);
@include mat.all-component-typographies($ivp-theme);
@include mat.all-component-densities($ivp-theme);
@include avatar.theme($ivp-theme);
@include follow-event.theme($ivp-theme);
// TODO Keep this for later use!
// .blue {
//   @include mat.all-component-colors($blue-theme);
//   @include avatar.theme($blue-theme);
//   @include button.theme($blue-theme);
//   @include chat-message.theme($blue-theme);
//   @include dialog-header.theme($blue-theme);
//   @include event-card.theme($blue-theme);
//   @include group-card.theme($blue-theme);
//   @include icon-button.theme($blue-theme);
//   @include image.theme($blue-theme);
//   @include not-available-card.theme($blue-theme);
//   @include two-column.theme($blue-theme);
//   @include profile-dialog.theme($blue-theme);
//   @include create-group-dialog.theme($blue-theme);
//   @include beta-label.theme($blue-theme);
//   @include invitation-dialog.theme($blue-theme);
// }
.ivp {
  @include mat.all-component-colors($ivp-theme);
  @include app.theme($ivp-theme);
  @include avatar.theme($ivp-theme);
  @include button.theme($ivp-theme);
  @include chat-message.theme($ivp-theme);
  @include dialog-header.theme($ivp-theme);
  @include event-card.theme($ivp-theme);
  @include group-card.theme($ivp-theme);
  @include group-card-small.theme($ivp-theme);
  @include icon-button.theme($ivp-theme);
  @include image.theme($ivp-theme);
  @include not-available-card.theme($ivp-theme);
  @include two-column.theme($ivp-theme);
  @include beta-label.theme($ivp-theme);
  @include invitation-dialog.theme($ivp-theme);
  @include profile-view-dialog.theme($ivp-theme);
}
